<template>
<div>
  <columns>
    <column>
      <form>
        <columns>
          <column>
            <text-input classes="is-rounded" required :error="$root.errors.name" :value="user.name" @input="updateName">
              First Name
            </text-input>
          </column>
          <column>
            <text-input classes="is-rounded" required :error="$root.errors.surname" :value="user.surname"
              @input="updateSurname">
              Surname
            </text-input>
          </column>
          <column>
            <text-input classes="is-rounded" :error="$root.errors.mobile" :value="user.profile.mobile" @input="updateMobile">
              Mobile Number
            </text-input>
          </column>
        </columns>
        <columns>
          <column>
            <submit-button class="is-rounded" :working="saving" @submit="saveProfile">
              Save
            </submit-button>
          </column>
        </columns>
      </form>
    </column>
    <column class="is-3">
      <div class="file has-name is-boxed">
        <label class="file-label">
          <input 
            @change="uploadImage"
            class="file-input" type="file" name="resume">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              Choose a file…
            </span>
          </span>
          <span class="file-name">
            Screen Shot 2017-07-29 at 15.54.25.png
          </span>
        </label>
      </div>
    </column>
  </columns>

  <columns>
    <column class="is-4">
      <p>Current Email Address: {{ user.email }}</p>

      <form>
        <text-input
          :error="$root.errors.email"
          v-model="email"
          classes="has-addons is-rounded">
          Email Address
          <template #right>
            <submit-button
              class="is-rounded"
              :working="updating_email"
              @click="updateEmail">
              Change Email
            </submit-button>
          </template>
        </text-input>
      </form>
    </column>
    <column>
      <p>Password</p>
      <form>
        <columns>
          <column>
            <password-input
              classes="is-rounded"
              required
              :error="$root.errors.password"
              v-model="password_change.password">
              New Password
            </password-input>
          </column>
          <column>
            <password-input
              classes="is-rounded"
              required
              :error="$root.errors.password_confirmation"
              v-model="password_change.password_confirmation">
              Password Confirmation
            </password-input>
          </column>
          <column class="is-narrow is-flex is-align-items-end">
            <submit-button
              class="is-rounded"
              :working="changing_password"
              @click="updatePassword">
              Change Password
            </submit-button>
          </column>
        </columns>
      </form>
    </column>
  </columns>
</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    saving: false,
    updating_email: false,
    changing_password: false,
    email: '',
    password_change: {
      password: '',
      password_confirmation: ''
    }
  }),

  methods: {
    ...mapMutations('auth', [
      'updateName',
      'updateSurname',
      'updateMobile'
    ]),
    saveProfile() {
      this.saving = true
      this.$store.dispatch('auth/updateProfile')
        .then(() => {
          this.$toast.success('Profile updated sucessfully')
          this.saving = false
        })
        .catch(() => {
          this.updating_email = false
      })
    },
    updateEmail() {
      this.updating_email = true
      this.$store.dispatch('auth/updateEmail', {
        email: this.email
      }).then(() => {
        this.updating_email = false
        this.email = ''
        this.$toast.success('Email address changed sucessfully')
      }).catch(() => {
        this.updating_email = false
      })
    },
    updatePassword() {
      this.changing_password = true
      this.$store.dispatch('auth/updatePassword', this.password_change).then(() => {
        this.changing_password = false
        this.password = {}
        this.$toast.success('Password changed sucessfully')
      }).catch(() => {
        this.changing_password = false
      })
    },
    uploadImage(event) {
      if(event.target.files.length) {
        this.$store.dispatch('auth/uploadProfilePicture', event.target.files[0]).then(() => {
          this.$toast.success('Profile picture updated')
        }).catch(() => {})
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ])
  }

}
</script>